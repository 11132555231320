.login-title {
    color: white;
    white-space: nowrap;
    border-width: 0;
    min-width: 160px;
    font-size: 3em;
    font-weight: bolder;
    text-align: center;
    margin: 80px 0px 48px;

    }

    .flex {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }


.login-box {
        background-color: black;
        color: white;
        row-gap: normal;
        border-radius: 8px;
         width: 734px;
}

.sign-in-text {
        width: 324px;
        margin: 0px auto;
        row-gap: normal;
}

.login-text-input {
        margin-bottom: 15px;
        appearance: none;
        background-image: none;
        border: 0px none;
        display: block;
        font-size: 1rem;
        font-weight: 400;
        font-family: var(--font-family,CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva,var(--fallback-fonts,sans-serif));
        transition: box-shadow 0.1s ease-in-out 0s, color 0.1s ease-in-out 0s;
        inline-size: 100%;
        box-sizing: border-box;
        margin-block: 0px;
        border-radius: var(--border-radius-md,4px);
        margin-top: 5px;
        padding-inline: 14px;
        padding-block-start: var(--spacer--2,8px);
        padding-block-end: var(--spacer--2,8px);
        min-block-size: var(--control-size-md,48px);
        background-color: #131313;
        box-shadow: inset 0 0 0 1px var(--essential-subdued,#878787);
        color: #FFF;
}

.login-text-input:hover {
    box-shadow: inset 0 0 0 1px #FFF;
}


.login-buttons {
    margin: 15px;
}

.login-button {
        box-sizing: border-box;
        font-size: 1rem;
        font-weight: 700;
        font-family: var(--font-family,CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva,var(--fallback-fonts,sans-serif));
        background-color: transparent;
        border-radius: 500px;
        text-align: center;
        text-decoration: none;
        text-transform: none;
        touch-action: manipulation;
        transition-duration: 33ms;
        transition-property: background-color, border-color, color, box-shadow, filter, transform;
        user-select: none;
        vertical-align: middle;
        transform: translate3d(0px, 0px, 0px);
        padding-block: 7px;
        padding-inline: 31px;
        position: relative;
        border: 1px solid var(--essential-subdued,#878787);
        color: white;
        background-color: var(--text-base,#000000);
        min-block-size: 48px;
        display: inline-flex;
        -moz-box-align: center;
        align-items: center;
        -moz-box-pack: center;
        justify-content: center;
        inline-size: 100%;
}

.login-button:hover {
    background-color: #131313;
}

.login-button:disabled {
    background-color: #2b2a2a;
    color: gray;
}


.loginBackground {
	flex: 3 1 0%;
	display: flex;
	-moz-box-pack: center;
	justify-content: center;
	padding: 32px;
	background: linear-gradient(rgba(255, 255, 255, 0.1) 0%, rgb(0, 0, 0) 100%);
}

.hr-seperator {
	margin: 32px 100px;
	border-color: rgb(62, 62, 62) currentcolor currentcolor;
	border-style: solid none none;
	border-width: 3px medium medium;
	border-image: none 100% / 1 / 0 stretch;
}

.spotify-button-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
