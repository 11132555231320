.header-text {
    color: #1FDF64;
    /* margin: 15px; */
    display: flex;
    font-weight: bold;
    font-size: 36px;
}


.header-box {
    background-color: black;
    height: 100px;
}

ul{  
    padding:0;  
    background:black;  
    font-family:Arial, sans serif;  
    font-size:12px;  
    text-transform:uppercase;
}

li{
    display:inline-block;
}

a{  
    display:block;  
    padding:10px;  
    color:white;  
    text-decoration:none;
}

.end {
    float: right;
}