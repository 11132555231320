/* html {
  background-color: black;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root {
  background-color: black;
}


.container {
  height: 200px;
  position: relative;
  border: 3px solid green;
}

.center {
  margin: auto;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.background-tile {
  background-color: #121212;
}

.background {
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow-x: hidden;
	background-color: black;
}

.danger {
  color: red;
}

