.signInButton {
    color: #000;
    background-color: #1FDF64;
    font-size: 14px;
    font-weight: bolder;
    line-height: 1;
    border-radius: 500px;
    padding: 20px 25px 20px;
    transition-property: background-color;
    transition-duration: .3s;
    border-width: 0;
    letter-spacing: 2px;
    min-width: 160px;
    text-transform: uppercase;
    white-space: normal;
    cursor: pointer;
    text-decoration: none;
    position: inherit;

}


.signInButton:hover {
    background-color: #19a74d;

}