.registerBackground {
	flex: 3 1 0%;
	display: flex;
	-moz-box-pack: center;
	justify-content: center;
	padding: 32px;
	background: linear-gradient(rgba(255, 255, 255, 0.1) 0%, rgb(255, 255, 255) 100%);
}

.register-box {
    background-color: #FFF;
    color: black;
    row-gap: normal;
    border-radius: 8px;
     width: 734px;
}

.register-title {
    color: #000;
    white-space: nowrap;
    border-width: 0;
    min-width: 160px;
    font-size: 3em;
    font-weight: bolder;
    text-align: center;
    margin: 80px 0px 48px;

}
